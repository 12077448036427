import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import './HomeFinancing.scss'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'
import tempImage from '../../assets/images/homeFinancingTemp.png'
import { addFormValidation } from '../../components/Validators/InputValidations'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create(option: {
          portalId: string
          formId: string
          target: string
          onFormReady: () => void
        }): void
      }
    }
    jQuery?: {}
  }
}

const HomeFinancing = (): ReactElement => {
  const financingItems = [
    {
      title: 'Cash',
      description:
        'Total selling price payable within 30 days; with 10% discount for non-RFO or 5% discount for RFO projects.',
    },
    {
      title: 'Deferred Cash',
      description: 'Total selling price over maximum of 24 months.',
    },
    {
      title: 'Bank Financing',
      description:
        'At least 10% down payment - 90% balance thru bank financing. *Prevailing bank interest rates apply',
    },
  ]

  const [isDetailsShowing, setIsDetailsShowing] = useState<boolean>(false)

  const handleFormReady = useCallback(() => {
    addFormValidation('request-form', [
      {
        elementId: 'firstname-4f79fd84-5236-4944-96a4-9c9d6714ed2e',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-4f79fd84-5236-4944-96a4-9c9d6714ed2e',
        validationType: 'numericOnly',
      },
    ])
    // add maxlength in contact number
    const addMaxLengthContacNo: HTMLElement | null = document.getElementById(
      'phone-4f79fd84-5236-4944-96a4-9c9d6714ed2e',
    )

    const isElement = (input: HTMLElement | null): input is HTMLElement =>
      input !== null

    if (isElement(addMaxLengthContacNo)) {
      addMaxLengthContacNo.setAttribute('maxLength', '12')
    }
  }, [])

  useEffect(() => {
    if (isDetailsShowing && window.hbspt?.forms) {
      window.jQuery =
        window.jQuery ||
        ((): { change(): null; trigger(): null } => ({
          change: (): null => null,
          trigger: (): null => null,
        }))

      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '2972d896-259a-4066-86b4-f63b832ccc8f',
        target: '#request-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady, isDetailsShowing])

  return (
    <Layout>
      <Seo
        title="Home Financing Options for Amaia Land Properties"
        jsonData={{
          '@type': 'Website',
        }}
        ogMetaData={{
          description:
            'Many urban dwellers continue to aspire to have their own abode and stop paying rent as it is often perceived wasteful. Owning a home is fast and easy now!',
        }}
      />

      <div className="home-financing">
        <div className="home-financing-info">
          <div className="home-financing-info-text">
            <h4 className="title">Home Financing</h4>
            <h4 className="tagline">
              Owning a home is made easy with various easy and convenient
              payment schemes.
            </h4>
            <h4 className="guide">
              To make it easier for you to avail of an Amaia home, we have a
              range of flexible financing options to choose from:
            </h4>

            {financingItems.map((option) => {
              return (
                <div key={option.title} className="options">
                  <div className="label">{option.title}</div>
                  <div className="description">{option.description}</div>
                </div>
              )
            })}
          </div>

          <button type="button" onClick={(): void => setIsDetailsShowing(true)}>
            Request Sample Computation
          </button>
        </div>
        <div className="home-financing-image">
          <img src={tempImage} alt="tempImage" />
        </div>
        <div className="slider-nav-container" />
      </div>

      <Modal
        className="left"
        noIcons
        side
        isShowing={isDetailsShowing}
        onClose={(): void => setIsDetailsShowing(false)}
      >
        <h4 className="title">Request for Sample Computation</h4>
        <div className="form" id="request-form" />
      </Modal>
    </Layout>
  )
}

export default HomeFinancing
